<template>
  <div>
    <h4>Track and Trace Apps</h4>
    <p>Please scan qr code.</p>
  </div>
</template>

<script>
export default {
  name: 'Home',
    mounted() {
    }
  }
</script>

<style scoped>
</style>
